@import "/src/styles/functions";
@import "/src/styles/_colors";

.cs_table_container {
  width: 100%;
  height: pxToRem(500);
}

:global(.ag-header-viewport) {
  background-color: map-get($grey, 7);
}

:global(.ag-header-container) {
  width: 100% !important;
}

:global(.ag-center-cols-container) {
  width: 100% !important;
}

.drop_down_ic {
  position: absolute;
  left: pxToRem(38);
  top: pxToRem(14);
}

:global(.ag-root-wrapper) {
  border: pxToRem(2) solid #f5f5f5 !important;
  border-radius: pxToRem(4);
}

.cs_center {
  display: flex;
  justify-items: center;
  align-items: center;
}

:global(.ag-checkbox-input-wrapper.ag-checked):after {
  color: map-get($blue, 1) !important;
}

.cs_h_max {
  height: 100%;
}

.cs_pagination_container {
  border: 1px #edece9 solid;
  padding: pxToRem(10) pxToRem(20);
  border-radius: 4px;
}

.cs_dis_flex {
  display: flex;
}

.cs_jc_sb {
  justify-content: space-between;
}

.page_btn {
  width: auto;
  height: pxToRem(10);
}

.box_container {
  display: flex;
  padding: 16px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 12px 0px;
  border-radius: 4px;
  border: 1px solid #edece9;

  .text {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
  }
}

:global(.ag-row-selected:before) {
  background-color: transparent;
}
