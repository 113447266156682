@use "sass:map";
@import "/src/styles/colors";
@import "/src/styles/functions";

.text- {

	/* Display */
	/// @example class="text-d1"
	&d1 {
		font-size: pxToRem(56);
	}

	&d2 {
		font-size: pxToRem(48);
	}

	&d3 {
		font-size: pxToRem(40);
	}

	&d1,
	&d2,
	&d3 {
		font-style: normal;
		font-weight: 700;
		line-height: 110%;
		letter-spacing: 0.01em;
	}

	/* Heading */
	/// @example class="text-h1"
	&h1 {
		font-size: pxToRem(32);
	}

	&h2 {
		font-size: pxToRem(28);
	}

	&h3 {
		font-size: pxToRem(24);
	}

	&h4 {
		font-size: pxToRem(20);
	}

	&h5 {
		font-size: pxToRem(16);
		letter-spacing: 0.005em;
	}

	&h1,
	&h2,
	&h3,
	&h4,
	&h5 {
		font-style: normal;
		font-weight: 700;
		line-height: 120%;
	}

	/* Sub heading */
	/// @example class="text-sh1"
	&sh1 {
		font-size: pxToRem(18);
	}

	&sh2 {
		font-size: pxToRem(16);
	}

	&sh3 {
		font-size: pxToRem(14);
	}

	&sh4 {
		font-size: pxToRem(12);
	}

	&sh1,
	&sh2,
	&sh3,
	&sh4 {
		font-style: normal;
		font-weight: 600;
		line-height: 130%;
	}

	/* Body */
	/// @example class="text-b1"
	&b1 {
		font-size: pxToRem(16);
		line-height: 140%;
	}

	&b2 {
		font-size: pxToRem(14);
		line-height: 150%;
	}

	&b3 {
		font-size: pxToRem(12);
		line-height: 160%;
	}

	&b1,
	&b2,
	&b3 {
		font-style: normal;
		font-weight: 400;
		color: var(--TypographyBodyColor, map-get($typography, "bodyColor"));
	}

	&d1,
	&d2,
	&d3,
	&h1,
	&h2,
	&h3,
	&h4,
	&h5,
	&sh1,
	&sh2,
	&sh3,
	&sh4 {
		color: var(--TypographyPrimaryColor, map-get($typography, "primaryColor"));
	}

	/// @example class="text-nav"
	&nav {
		font-style: normal;
		font-weight: 400;
		font-size: pxToRem(14);
		line-height: 140%;
	}
}
