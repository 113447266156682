@import "/src/styles/functions";

.cs_center {
  display: flex;
  align-items: center;
  gap: pxToRem(8);
}

.cs_p_20 {
  padding: 2rem 2rem 2rem 0;
}

.cs_dis_flex {
  display: flex;
}
