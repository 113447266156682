@import "src/styles/functions";

.right-panel-wrapper {
  width: 100%;
  height: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}

.right-panel-top-section {
  padding: 8px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border-radius: 8px 8px 0 0;
  border-bottom: 1px solid #e0e0e0;

  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #7d7676;
    margin: 0%;
  }
}

.icon-wrapper {
  display: flex;
}

.icon-wrapper > div:first-child {
  margin-right: pxToRem(12);
}

.icon {
  height: pxToRem(24);
  width: pxToRem(24);
  display: flex;
  justify-content: center;
  align-self: center;
  cursor: pointer;

  svg {
    margin: auto;
    path {
      fill: #828282;
    }
  }
}

.active {
  border: 1px solid #f2f2f2;
  border-radius: 2px;
  background: #f8f8f8;
}

.browser-img {
  padding-top: 16px;
  background-color: #eaeaea;
  width: 100%;
  svg {
    width: 100%;
  }
}

.preview-wrapper {
  width: 100%;
  height: unset;
  border-radius: 8px;
  background-color: #eaeaea;
  overflow: hidden;
}

.preview-wrapper-mobile {
  display: flex;
  justify-content: center;
  background-color: #eaeaea;
  height: unset;
  border-radius: 8px;
}
