@import "/src/styles/functions";

.cs_dis_flex {
  display: flex;
}

.cs_center {
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.cs_height{
  height: 55vh;
}