.chart_info_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  font-family: Inter;
  font-size: 14px;
}
.padding_right_10 {
  padding-right: 20px;
}
.chart_info_row_container {
  display: grid;
  width: 100%;
  grid-template-columns: 32% 35% 12% 55%;
  justify-content: center;
  align-items: center;
  grid-column-gap: 6px;
}

.green {
  color: #0a5f23;
}

.red {
  color: #c13c3c;
}
