.card_container {
    display: flex;
    padding: 16px 32px 16px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 12px;
    border: 1px solid var(--stroke-highlighter-highliter, #EDECE9);
    background: var(--backgrounds-white, #FFF);
}

.value_container {
    display: flex;
    align-items: center;
}
.text_value {
    color: #333;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 0.09px;
    margin-right: 8px;
}
.decrease{
    color:#C13C3C;
}
.increase{
    color: #0A5F23;
}
.text_name {
    color: #828282;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 18px */
}