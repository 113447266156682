@import "/src/styles/functions";

.cs_center {
  display: flex;
  justify-items: center;
  align-items: center;
  margin-right: pxToRem(8);
}

.cs_dis_flex {
  padding: pxToRem(16) pxToRem(8);
}
