@import "/src/styles/functions";

.select-wrapper :global(.n-dropdown-container .n-select__trigger) {
  border: 1px solid #e0e0e0;
  border-radius: pxToRem(4);
  padding: pxToRem(11);
}

.select-wrapper :global(.n-dropdown-container) {
  min-width: 175px;
  width: 100%;
  //   padding: 0px;
  //   font-size: pxToRem(12) !important;
}

.select-wrapper :global(.n-dropdown-container .n-dropdown-input-arrow-wrapper) {
  span {
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
  }
}

.select-wrapper :global(.n-focused-border) {
  border: 1px solid #000093 !important;
  border-radius: pxToRem(4);
}

.text-box-font-size {
  font-size: pxToRem(12) !important;
}

.text-box-font-size::placeholder {
  font-size: pxToRem(12) !important;
}
