@import "/src/styles/functions";
@import "/src/styles/colors";

@mixin flex {
  display: flex;
}

.main_container {
  @include flex();
  border-radius: 4px;
  border: 1px solid #d4e2ff;
  background: #f6f9ff;
  padding: pxToRem(10);
  width: 100%;
  gap: pxToRem(8);
}

.icon_container {
  @include flex();
  justify-content: center;
  align-items: center;
  width: pxToRem(36);
  height: pxToRem(36);
  border-radius: 50%;
  background-color: #e7eeff;
}

.text_container {
  @include flex();
  flex-direction: column;
}
