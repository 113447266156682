@import "/src/styles/functions";

.cs-dis-flex {
  display: flex;
}

.cs-jc-sb {
  justify-content: space-between;
  align-items: center;
}

.cs-collapse-img-btn {
  width: pxToRem(20);
  height: pxToRem(20);
}

.cs-pointer {
  cursor: pointer;
}
