@import "/src/styles/functions";

.table_wrapper {
  border: 1px solid #edece9;
  border-radius: pxToRem(12);
}

.table {
  color: #41434c;
  border-spacing: 0;
  border-collapse: collapse;
  border-style: hidden;
  padding: 12px;
  color: #41434c;
  font-size: pxToRem(12);
  width: 100%;
}

.table_action_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: pxToRem(5);
}

.table_action_button {
  display: flex;
  padding: 1rem;
  border: 1px solid #edece9;
  border-radius: 50%;
  cursor: pointer;
  background: none;
  outline: none;
}

.table_heading {
  background: #f9f9f9;
}

.table tr {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border-bottom: 1px solid #edece9;
  padding: 12px;
  text-align: center;
  align-items: center;
  gap: pxToRem(10);
}

