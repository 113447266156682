@import "/src/styles/functions";
@import "/src/styles/colors";

.cs_review_detail_layout {
  display: grid;
  grid-template-columns: 1fr 200px;
  column-gap: pxToRem(24);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.cs_review_detail_badge {
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cs_vt_division {
  height: 70%;
  width: pxToRem(1);
  background-color: map-get($map: $grey, $key: 4);
  margin: 0px 5px;
}

.cs_verified_png {
  width: pxToRem(18);
  height: pxToRem(18);
}

.cs_generate_btn_ic {
  width: pxToRem(16);
  height: pxToRem(13);
}

.cs_generate_btn {
  color: map-get($map: $blue, $key: 1);
  font-weight: 700;
}

.icon {
  display: flex;
  padding: pxToRem(6);
  border-radius: 50%;
  margin-right: pxToRem(6);
  background-color: #f3f3ed;
}

.box {
  width: 100%;
  border-radius: 4px;
  background: #f8f8f8;
  padding: pxToRem(20);
  position: relative;

  .box_heading {
    color: #261a1a;
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.28px;
    margin-bottom: 8px;
  }

  .box_description {
    color: #3c3131;
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.28px;
  }
}

.box.arrow_top {
  margin-top: 20px;
}

.box.arrow_top:after {
  content: " ";
  position: absolute;
  left: 13px;
  top: -11px;
  border-top: none;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 15px solid #f8f8f8;
}
