@import "/src/styles/functions";
@import "/src/styles/colors";

.radio_group {
  display: flex;
  justify-content: space-between;
  gap: pxToRem(16);
  margin-bottom: pxToRem(14);
}

.radio_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: pxToRem(12);
  padding: 16px;
  background: #f5f8ff;
  border-radius: 12px;
  border: 1px solid #e7eeff;

  &.selected {
    box-shadow: 0 0 0 2px map-get($blue, 1);
  }
}

.radio_button {
  border: pxToRem(0.5) solid #ededed;
  background: white;
  border-radius: 50%;
  padding: pxToRem(8);
  display: flex;
  justify-content: center;
  align-items: center;
}
