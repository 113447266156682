@import "/src/styles/functions";
@import "/src/styles/colors";

.cs-heading-1 {
  font-weight: 600;
  font-size: pxToRem(18);
  line-height: pxToRem(24);
  color: map-get($map: $typography, $key: primaryColor);
}

.cs-heading-2 {
  font-weight: 600;
  font-size: pxToRem(16);
  line-height: pxToRem(24);
  color: map-get($map: $typography, $key: primaryColor);
}

.cs-heading-3 {
  font-weight: 700;
  font-size: pxToRem(24);
  line-height: pxToRem(24);
  color: map-get($map: $typography, $key: primaryColor);
}

.cs-header {
  font-weight: 500;
  font-size: pxToRem(16);
  color: #3c3131;
  line-height: 160%;
}
.cs-heading {
  font-weight: 500;
  font-size: pxToRem(14);
  color: #3c3131;
  line-height: 160%;
}

.cs-title {
  font-size: pxToRem(13);
  color: #41434c;
  line-height: 140%;
  margin-bottom: pxToRem(4);
}

.cs-label {
  font-weight: 400;
  font-size: pxToRem(12);
  color: #9b9b9b;
}

.cs-section-heading {
  color: #41434c;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.cs-section-description {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  color: rgba(102, 102, 102, 0.5);
}

.cs_text_ellipsis {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
}

.read_more {
  color: #2e31be;
  text-decoration: underline;
  cursor: pointer;
}

.cs_text_expand {
  white-space: break-spaces;
}

.cs-product-description {
  color: var(--text-body, #3c3131);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
  letter-spacing: -0.28px;
}
