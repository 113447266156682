@import "/src/styles/functions";

.cs-grid-container {
  display: grid;
}

.cs-template-30-70 {
  grid-template-columns: 40% 60%;
}

.cs-grid-of-two {
  grid-template-columns: 50fr 50fr;
}

.cs-column-gap-25 {
  gap: pxToRem(25);
margin-right: pxToRem(24);
}

.cs-column-gap-16 {
  gap: pxToRem(16);
}

.cs-row-gap {
  row-gap: pxToRem(14);
}

.cs-row-flex {
  display: flex;
}

.cs-justify-row {
  justify-content: space-between;
}
