@import "/src/styles/functions";

.card_main_container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: pxToRem(16);
  width: 100%;
}

.dashboard_container {
  width: 100%;
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: pxToRem(24);
}

.donut_chart_container {
  margin-right: 20px;
}

.analytics_text {
  color: #41434c;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.analytics_dropdown {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
}

.analytics_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  margin-top: pxToRem(16);
  margin-bottom: pxToRem(16);
}

.line_graph_container {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex: 1 0 0;
  border-radius: 12px;
  border: 1px solid var(--stroke-highlighter-highliter, #edece9);
  margin-top: pxToRem(16);
  margin-bottom: pxToRem(16);
}

.dashboard_container canvas {
  cursor: pointer;
}

.chart_heading {
  color: #41434c;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.line_graph {
  width: 100%;
}

.vertical_center {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.increased_value {
  color: #0a5f23;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
}

.increased_value img {
  color: #0a5f23;
}

.decreased_value {
  color: #c13c3c;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
}

.decreased_value img {
  color: #c13c3c;
}

.top_rated_container {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex: 1 0 0;
  border-radius: 12px;
  border: 1px solid var(--stroke-highlighter-highliter, #edece9);
}

.four_box_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: pxToRem(16);
  grid-row-gap: pxToRem(16);
}

// .four_box_container > div {
//   margin-bottom: 16px;
// }

.filter_dropdown {
  color: var(--text-color-labels-placeholder, #9b9b9b);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.dis_flex {
  display: flex;
}

.chart_info_container {
  width: 20vw;
  margin: auto;
  padding-left: 2rem;
}

.h_65 {
  height: 65%;
}

.flex_gap {
  gap: 1rem;
}

.chart_info_bottom {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 50% 50%;
  align-self: stretch;
}

.border_1 {
  border: 1px solid #edece9;
  border-radius: 8px;
}

.nps_text_container {
  border-top: 1px solid #edece9;
  padding: 24px 16px 0px 16px;
}

.border_bottom_1 {
  border-bottom: 1px solid #edece9;
}

.promotors_arrange {
  font-size: small;
  display: flex;
  justify-content: space-between;
  width: 200%;
  align-items: center;
}

.total_reviews_title {
  color: #41434c;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.promotors_btn {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  border: none;
  color: white;
  background: #2e31be;
  font-weight: 800;
}

.margin_auto {
  // margin: auto;
}

.highlighted_text {
  display: flex;
  padding: 24px 16px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background: #f8f8f8;
  flex: 1 0;
  align-self: stretch;
}

.flex_dir_column {
  flex-direction: column;
}

.space_between {
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
}

.highlighted_percentage {
  color: #41434c;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}

.highlight_title {
  color: #41434c;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.ratings_text {
  color: #41434c;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.number_ratings {
  color: #41434c;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
}

.highlight_text {
  color: #2e2c27;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.empty_state {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  width: 100%;
  height: 100%;
}

.title {
  align-self: flex-start;
}
