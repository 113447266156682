@use "sass:map";
@import "/src/styles/functions";

.cs-grid-container {
  display: grid;
}

$template-value: 2, 3, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26;

@each $value in $template-value {
  .cs-grid-count-#{$value} {
    grid-template-columns: repeat(#{$value}, 1fr);
  }
}

.cs-tab-title {
  font-weight: 500;
  font-size: pxToRem(15);
  line-height: pxToRem(21);
  color: #4f4f4f;
  border-bottom: pxToRem(3) solid #2e30be00;
}

.cs-center {
  display: flex;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
}

.cs-cursor-pointer {
  cursor: pointer;
}

.cs-active-tab {
  color: #2e31be;
  font-weight: 700;
  border-bottom: pxToRem(3) solid #2e31be;
}

.cs-bp-10 {
  padding-bottom: pxToRem(10);
}

.cs-tab-notify-count {
  background-color: #f2c94c;
  border-radius: 50%;
  width: pxToRem(18);
  height: pxToRem(17);
  font-size: pxToRem(12);
  margin-left: pxToRem(8);
  color: #4F4F4F;
}
