@import "/src/styles/functions";

.cs_back_arrow {
  height: pxToRem(18);
  width: pxToRem(18);
}

.cs_dis_flex {
  display: flex;
}

.cs_rm_5 {
  margin-right: pxToRem(5);
}

.cs_cursor_pointer{
  cursor: pointer;
}