@import "/src/styles/functions";

.input-text-wrapper :global(.n-input-container) {
  border: 1px solid #e0e0e0;
  border-radius: pxToRem(4);
  background-color: #fff;
}

.input-text-wrapper :global(.n-input-maxLength) {
  display: none;
}

.input-text-wrapper :global(.n-focused-border) {
  border: 1px solid #000093 !important;
  border-radius: pxToRem(4);
}

.input-error :global(.n-focused-border) {
  border: 1px solid #EB5757 !important;
  border-radius: pxToRem(4);
}

.text-box-font-size {
  font-size: pxToRem(12) !important;
}

.text-box-font-size::placeholder {
  font-size: pxToRem(12) !important;
}
