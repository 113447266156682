@import "/src/styles/functions";

.card {
  border-radius: pxToRem(12);
  border: 1px solid #edece9;

  display: flex;
  padding: 10px 20px 20px 20px;
  flex-direction: column;
  gap: 6px;

  .description {
    color: rgba(102, 102, 102, 0.8);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    opacity: 0.8999999761581421;
  }
}

.cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: pxToRem(16);
  grid-column-gap: pxToRem(16);
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.upgrade {
  padding: 9.5px 16px;
  border-radius: 4px;
  border: 1px solid var(--brand-color-accent-or-primary, #2e31be);
  background: #fff;

  color: var(--brand-color-accent-or-primary, #2e31be);
  /* Button/Medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  cursor: pointer;
}
