@import "src/styles/functions";

.side_drawer {
  height: 100%;
  background: white;
  position: fixed;
  top: 0;
  right: 0;
  width: 60vw;
  z-index: 200;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  transform: translateX(100%);
  transition: transform 0.8s ease-out;
  padding: pxToRem(24);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.cross_button {
  outline: none;
  background: none;
  cursor: pointer;
  border: none;
}

.side_drawer.open {
  transform: translateX(0);
}

.w_full {
  width: 100%;
}
