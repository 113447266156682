@import "/src/styles/functions";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tab_wrapper {
  width: 60%;
}

.auto_wrapper {
  align-self: flex-start;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
