@import "/src/styles/functions";

.section-heading-box {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.svg-box {
  height: pxToRem(20);
  width: pxToRem(20);
  margin-right: pxToRem(8);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.toggle-label {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #2e31be;
  margin-right: pxToRem(8);
}

.section-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.upgrade {
  padding: 9.5px 16px;
  border-radius: 4px;
  border: 1px solid var(--brand-color-accent-or-primary, #2e31be);
  background: #fff;

  color: var(--brand-color-accent-or-primary, #2e31be);
  /* Button/Medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  cursor: pointer;
}
