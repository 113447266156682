@import "/src/styles/functions";
@import "/src/styles/colors";

.cs-rewards {
  display: flex;
  justify-content: space-between;
}
.cs-settings {
  display: flex;
}
.cs-settings-left {
  width: 56%;
  margin-right: pxToRem(24);
}
.cs-toggle-button {
  display: flex;
  align-items: center;
}
.cs-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cs-cards {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: pxToRem(24);
}
.cs_pr_off,
.cs_fa_off {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  width: 192px;
  background: #f5f8ff;
  border: 1px solid #edece9;
  border-radius: 8px;
}

.cs-inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.active {
  border: 2px solid #2e31be;
  color: #2e31be;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  width: 192px;
  background: #f5f8ff;
  border-radius: 8px;
}
::v-deep .rewards_active__jxw-4 .text_cs-header__A8ynH {
  color: blue !important;
}
.cs-dis-val {
  margin-right: 18px;
  width: 100%;
}
.cs-max-val {
  width: 100%;
}
.cs-buy-review {
  margin-bottom: pxToRem(24);
}
.cs-save-btn {
  margin-top: pxToRem(24);
}

.cs_grid_of_two {
  display: flex;
  justify-content: space-between;
}

.cs_column_gap_16 {
  gap: pxToRem(16);
}

.error_text {
  color: red;
  font-size: 1rem;
  font-weight: 500;
}
