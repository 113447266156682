.table_header_container {
  display: grid;
  padding: 14px 16px;
  border-radius: 4px 4px 0px 0px;
  grid-gap: 5%;
  grid-template-columns: 60% 40%;
  border: 1px solid var(--stroke-highlighter-highliter, #edece9);
  background: #f5f5f5;
  width: 100%;
  margin-top: 20px;

  color: #41434c;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.font_size_larger {
  font-size: larger;
}
.table_data_row {
  display: grid;
  padding: 14px 16px;
  grid-gap: 5%;
  grid-template-columns: 60% 40%;
  border: 1px solid var(--stroke-highlighter-highliter, #edece9);
  width: 100%;
}
.product_info img {
  width: 53px;
  height: 57.181px;
  border-radius: 4px;
}
.dis_flex {
  display: flex;
}
.m_l_8 {
  margin-left: 8px;
}
.fw_600 {
  font-weight: 600;
}
.fw_400 {
  font-weight: 400;
}
.ratings {
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 600;
}
.date_row {
  display: flex;
  color: var(--text-primary, #41434c);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  align-items: center;
}
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.review_count {
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}
