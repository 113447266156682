@import "src/styles/functions";

.layout-container {
  display: flex;
  background: #fff;
  border: 1px solid #edece9;
  border-radius: 12px;
  margin: 0 24px 24px 24px;
  overflow: hidden;
}

.help_text {
  color: #2e31be;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
}

.feature-menu {
  width: 78px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  background-color: #f9f9f9;
  border-right: 1px solid #edece9;
}

.menuItem {
  min-height: 76px;
  border-bottom: 1px solid #edece9;
  background-color: #f9f9f9;

  .svg-container {
    margin-top: 12px;
    display: flex;
    justify-content: center;
  }

  .circle {
    background: #fff;
    border-radius: 100%;
    height: 36px;
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #edece9;
  }

  .menu-title {
    margin-top: 4px;
    font-size: 10px;
    line-height: 120%;
    text-align: center;
    padding: 0 9px 12px;
  }

  &:first-child {
    border-top-left-radius: 12px;
  }
}
.active {
  background: #fff;
  border-right: 1px solid #fff;
  margin-right: -1px;
  .menu-title {
    color: #2e31be;
  }
  svg {
    path {
      fill: #2e31be;
    }
  }
}

.feature-wrapper {
  width: 100%;
  height: 100%;
}

.feature-component {
  padding: pxToRem(24);
  height: 100%;
  width: 100%;
}

.toggle_box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: pxToRem(10);
}
