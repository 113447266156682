@import "/src/styles/functions";

.tags_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: pxToRem(6);
  margin-bottom: pxToRem(8);
}

.tag {
  display: flex;
  padding: pxToRem(4) pxToRem(10);
  justify-content: center;
  align-items: center;

  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  color: #2e31be;

  border-radius: 4px;
  border: 1px solid rgba(46, 49, 190, 0.3);
}
