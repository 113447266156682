@import "/src/styles/functions";
@import "/src/styles/colors";

.cs_prd_overview_card {
  background-color: map-get($map: $grey, $key: 14);
  border: 1px solid map-get($map: $grey, $key: 13);
  padding: pxToRem(16);
  border-radius: pxToRem(8);
  width: 200px;
}

.cs_cursor {
  cursor: pointer;
}

.cs_cs_prd_overview_image {
  object-fit: cover;
  width: pxToRem(90);
  height: auto;
}

.cs_order_details {
  display: flex;
  align-items: center;
}
