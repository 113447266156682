@import "/src/styles/functions";

.cs_center {
  display: flex;
  justify-items: center;
  align-items: center;
  margin-right: pxToRem(8);
}

.cs_p_20 {
  padding: pxToRem(20);
}

.cs_dis_flex {
  display: flex;
}
