@import "/src/styles/functions";
@import "/src/styles/colors";

.cs_review_detail_layout {
  display: grid;
  grid-template-columns: 1fr 200px;
  column-gap: pxToRem(24);
}

.cs_review_detail_badge {
  width: pxToRem(107);
}

.cs_vt_division {
  height: 70%;
  width: pxToRem(1);
  background-color: map-get($map: $grey, $key: 4);
  margin: 0px 5px;
}

.cs_verified_png {
  width: pxToRem(18);
  height: pxToRem(18);
}

.cs_generate_btn_ic {
  width: pxToRem(16);
  height: pxToRem(13);
}

.cs_generate_btn {
  color: map-get($map: $blue, $key: 1);
  font-weight: 700;
}
