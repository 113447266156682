.iframe {
  all: unset;
  display: block;
  height: 756px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 0 0 8px 8px;
}

.iframeMobile {
  all: unset;
  display: block;
  height: 756px;
  width: 480px;
  background-color: #ffffff;
  border-radius: 0 0 8px 8px;
}
