@import "./grid-variables";

.row,
.grid {
	display: grid;
	row-gap: $gridRowGap;
	column-gap: $gridColumnGap;
	grid-template-columns: repeat($gridColumns, #{$gridColumns}fr);
}

/// $breakPoints {map<$key: string, $value: string>}
/// $screenType {key in $breakPoints} default values xl, lg, md, sm, xs
/// $size {value in $breakPoint} default values 1200px, 992px, 768px, 576px, 0
@each $screenType,
$size in $breakPoints {
	@media screen and (min-width: $size) {

		/// creates classes of type col-**-##
		/// where ** can be xl, lg, md, sm, xs
		/// ## can be 2 to 12
		@for $column from $gridColumns through 2 {
			#{$columnPrefix}-#{$screenType}-#{$column} {
				grid-column: span $column;
			}
		}

		/// creates classes of type d-**-none
		/// where ** can be xl, lg, md, sm, xs
		/// hides the element when the screen width is equal to `$size`
		.d-#{$screenType}-none {
			display: none;
		}
	}
}

/// creates classes of type col-##
/// where ## can be 2 to 12
@for $column from $gridColumns through 2 {
	#{$columnPrefix}-#{$column} {
		grid-column: span $column;
	}
}


@each $alignment,
$value in $horizontal-alignment {

	/// horizontally align grid items
	[class*="#{$columnPrefix}"] {
		&#{$alignPrefix}-#{$alignment} {
			justify-self: $value;
		}
	}

	/// horizontally align grid
	.grid#{$alignPrefix}-#{$alignment} {
		justify-content: $value;
	}
}


@each $alignment,
$value in $vertical-alignment {

	/// vertically align grid items
	[class*="#{$columnPrefix}"] {
		&#{$alignPrefix}-#{$alignment} {
			align-self: $value;
		}
	}

	/// vertically align grid
	.grid#{$alignPrefix} {
		&-#{$alignment} {
			align-items: $value;
		}
	}

}

/// center align both horizontally and vertically
[class*="#{$columnPrefix}"].center {
	place-self: center;
}

.grid,
.row {
	&.center {
		place-items: center;
	}
}
