.proContainer {
  display: flex;
  justify-content: right;
}

.pro {
  height: 22px;
  width: 32px;
  background: #fff;
  position: absolute;
  color: #2e31be;
  border-bottom-left-radius: 6px;
  padding: 4px 6px;
  border-top-right-radius: 5px;
}

.cardPro {
  margin: 15px;
  border-radius: 2px;
  background: #e7eeff;
}
