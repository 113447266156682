@import "/src/styles/functions";

.star_rating_wrapper {
  display: flex;
  gap: pxToRem(3);
}

.star_button {
  background-color: transparent;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

// To add a different color to the star use the below filter-{colorName}
.filter_green {
  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg)
    brightness(118%) contrast(119%);
}
