@import "/src/styles/functions";

.cs-grid-container {
  display: grid;
}

.cs-template-30-70 {
  grid-template-columns: 4fr 6fr;
}

.cs-grid-of-two {
  grid-template-columns: 50fr 50fr;
}

.cs-column-gap-25 {
  gap: pxToRem(25);
}

.cs-column-gap-16 {
  gap: pxToRem(16);
}

.cs-vt-center {
  display: flex;
  align-items: center;
  align-content: center;
}
