@import "/src/styles/functions";

.cs-width-max {
  width: 100%;
}

.cs-obj-fit-cover{
  object-fit: cover;
}

.cs-box-border{
  // border: 1px solid #5b5ef8;
  border: 1px solid #5b5ef8;
  border-radius: 8px;
  overflow: hidden;
}