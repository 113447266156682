@use "sass:map";
@import "/src/styles/functions";

$border-radius-values: 2, 4, 6, 8, 10;

@each $value in $border-radius-values {
  .cs-br-#{$value} {
    border-radius: #{$value}px !important;
  }
}
