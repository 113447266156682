@import "/src/styles/functions";
@import "/src/styles/colors";

.box {
  width: 100%;
  border-radius: 4px;
  background: #f8f8f8;
  padding: pxToRem(20);
  position: relative;

  .box_heading {
    color: #261a1a;
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.28px;
    margin-bottom: 8px;
  }

  .box_description {
    color: #3c3131;
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.28px;
  }
}

.box.arrow_top {
  margin-top: 20px;
}

.box.arrow_top:after {
  content: " ";
  position: absolute;
  left: 13px;
  top: -11px;
  border-top: none;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 15px solid #f8f8f8;
}
