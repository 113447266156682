@import "/src/styles/functions";

.cs-grid-container {
  display: grid;
  column-gap: pxToRem(16);
  grid-template-columns: auto auto auto;
}

.cs-box-border {
  // border: 1px solid #5b5ef8;
  border: 1px solid #5b5ef8;
  background: #f5f8ff;
  border-radius: 8px;
  overflow: hidden;
}

.selected {
  border: 2px solid #2e31be;
}

.not-selected {
  padding: 1px;
}
