@import "/src/styles/functions";
@import "/src/styles/colors";

.cs_prd_image_list {
  height: pxToRem(84);
  width: pxToRem(84);
  border-radius: pxToRem(4);
}

.cs_prd_image_list_container {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
}

.cs_prd_card {
  border: pxToRem(1) solid map-get($map: $grey, $key: 13);
  padding: pxToRem(16) pxToRem(16);
  border-radius: pxToRem(8);
}

.cs_blur_image {
  filter: blur(3px);
}
