@import "/src/styles/functions";

.cs-grid-container {
  display: grid;
}

.cs-template-30-70 {
  grid-template-columns: 40% 60%;
}

.cs-template-60-40 {
  grid-template-columns: 60fr 40fr;
}

.cs-template-1-0 {
  grid-template-columns: 1fr 0fr;
}

.cs-grid-of-two {
  grid-template-columns: 50fr 50fr;
}

.cs-column-gap-25 {
  gap: pxToRem(25);
margin-right: pxToRem(24);
}

.cs-column-gap-10 {
  gap: pxToRem(10);
}

.cs-column-gap-16 {
  gap: pxToRem(16);
}

.cs-row-gap {
  row-gap: pxToRem(14);
}

.cs-justify-content {
  justify-content: center;
}

.cs-align-items {
  align-items: center;
}
