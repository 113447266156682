@import "/src/styles/functions";
@import "/src/styles/colors";

@mixin margin-bottom {
  margin-bottom: pxToRem(20);
}

@mixin flex {
  display: flex;
}

.card {
  @include flex();
  flex-direction: column;
  padding: pxToRem(20);
  justify-content: space-between;

  border: 1px solid #e0e0e0;
  border-radius: pxToRem(12);
}

.card_image {
  @include margin-bottom();
}

.card_heading {
  @include margin-bottom();
}

.card_description {
  @include margin-bottom();
}

.card_footer {
  @include flex();
  justify-content: space-between;
  align-items: center;
}

.card_arrow_button {
  @include flex();
  padding: pxToRem(8);
  border: 1px solid map-get($map: $grey, $key: 2);
  border-radius: pxToRem(3);
  background: none;
  cursor: pointer;
}
