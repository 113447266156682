@import "/src/styles/functions";

.radio-wrapper {
  display: flex;
}

.radio-box {
  margin-right: 8px;
  label{
    font-size: pxToRem(14);
    line-height: 150%;
    color: #41434C;
  }
}

.vertical-box{
  display: flex;
  flex-direction: column;
  align-self: center;
  .radio-wrapper{
    margin-top: pxToRem(9.5);
  }
}

.horizontal-box{
  display: flex;
  flex-direction: row;
  align-self: center;
  .radio-wrapper{
    margin-left: pxToRem(12);
  }
}