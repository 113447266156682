@import "src/styles/functions";

.pro-layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}

.pro-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding-bottom: 24px;
}

.upgrade {
  color: var(--text-color-primary-and-secondary, #41434c);
  text-align: center;
  /* Sub Headings/SH1 */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
}

.success-button {
  width: 140px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid var(--brand-color-accent-or-primary, #2e31be);
  color: var(--backgrounds-white, #fff);
  background: var(--brand-color-accent-or-primary, #2e31be);

  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}

.cancel {
  color: var(--brand-color-accent-or-primary, #2e31be);
  background: var(--brand-color-accent-or-primary, #fff);
}
