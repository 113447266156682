@import "/src/styles/functions";

.cs_center {
  display: flex;
  justify-items: center;
  align-items: center;
}

.cs_p_20 {
  padding: pxToRem(20);
}

.cs_dis_flex {
  display: flex;
}

.cs_pagination_container {
  border: 1px #edece9 solid;
  padding: pxToRem(10) pxToRem(20);
  border-radius: 4px;
}

.cs_jc_sb {
  justify-content: space-between;
}

.page_btn {
  width: auto;
  height: pxToRem(10);
}
