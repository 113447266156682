@import "../../styles//_functions.scss";
@import "../../styles/_colors.scss";

.heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: pxToRem(24);
  line-height: 120%;
  color: map-get($map: $typography, $key: primaryColor);
  margin: pxToRem(32) 0 pxToRem(8) 0;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: pxToRem(44);
}

.description {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: pxToRem(12);
  line-height: 160%;
  color: map-get($map: $typography, $key: primaryColor);
  margin-bottom: pxToRem(44);
}

.card_list {
  display: flex;
  gap: pxToRem(32);
}
