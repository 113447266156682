@import "src/styles/functions";

datalist {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  option {
    padding: 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: pxToRem(14);
    line-height: 140%;
    color: #41434c;
  }
}

.slide-range {
  display: flex;
  align-items: center;
  gap: pxToRem(10);
  height: pxToRem(24);
  width: 100%;

  input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    cursor: pointer;
    outline: none;
    border-radius: pxToRem(15);
    height: pxToRem(4);
    background: #e7eeff;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: pxToRem(16);
    width: pxToRem(16);
    background-color: #2e31be;
    border-radius: 50%;
    transition: 0.2s ease-in-out;
  }

  input[type="range"]::-moz-range-thumb {
    height: pxToRem(16);
    width: pxToRem(16);
    background-color: #2e31be;
    border-radius: 50%;
    border: none;
    transition: 0.2s ease-in-out;
  }
}
