@import "../../../styles/functions";
@import "../../../styles/colors";

.card {
  background: #ffffff;
  border: pxToRem(0.64) solid #f3f3f3;
  box-shadow: 0 pxToRem(2.56) pxToRem(12.8) rgba(80, 80, 80, 0.1);
  border-radius: pxToRem(8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: pxToRem(248);
  padding: 0 pxToRem(24);
  position: relative;
}

.popular {
  border: pxToRem(2) solid #2e31be;
}

.popular::before {
  position: absolute;
  content: "Most Popular";
  background: #283593;
  width: pxToRem(103);
  height: pxToRem(24);
  background: #e7eeff;
  border: pxToRem(1.5) solid #2e31be;
  border-radius: pxToRem(4);
  top: pxToRem(-12);
  color: #2e31be;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card_details_list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: pxToRem(4);
  margin: pxToRem(24) 0;
}

.card_heading {
  font-style: normal;
  font-weight: 600;
  font-size: pxToRem(18);
  line-height: 130%;
  color: map-get($map: $typography, $key: primaryColor);
  margin-top: pxToRem(32);
  margin-bottom: pxToRem(8);
  text-transform: capitalize;
  text-align: center;
}

.card_tagline {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: pxToRem(12);
  line-height: 160%;
  color: map-get($map: $grey, $key: 15);
  margin-bottom: pxToRem(12);
  text-align: center;
}

.card_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: pxToRem(11);
  line-height: 140%;
  color: map-get($map: $typography, $key: bodyColor);
  text-align: center;
}

.card_pricing {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: pxToRem(24);
  line-height: 120%;
  color: map-get($map: $typography, $key: primaryColor);
  text-align: center;
}

.card_button {
  margin-bottom: pxToRem(24);
}

.buttonStyle {
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 12px;
  font-weight: 700;
  line-height: 160%;
  cursor: pointer;
}

.popularPlanButtonStyles {
  color: #ffffff;
  background: #2e31be;
  border: pxToRem(1) solid #2e31be;
}

.selectPlanButtonStyles {
  color: #2e31be;
  background: #ffffff;
  border: pxToRem(1) solid #2e31be;
}
