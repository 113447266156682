@import "/src/styles/functions";
@import "/src/styles/colors";

$height: pxToRem(40);
$color-height: pxToRem(38);

.color-input {
    display: flex;
    gap: pxToRem(8);
    align-items: center;
    height: $height;
    &__ {
        &text {
            flex-grow: 2;
            display: grid;
            grid-template-columns: 26px minmax(10ch, 1fr) 38px;
            border: 1px solid map-get($grey, 5);
            border-radius: pxToRem(4);
            height: 100%;
        }
        &unit {
            font-style: normal;
            font-weight: 500;
            font-size: pxToRem(12);
            line-height: pxToRem(24);
            text-align: center;
            color: map-get($grey, 3);
            border-right: 1px solid map-get($grey, 5);
            flex-shrink: 0;
            cursor: default;
        }
    }
    input[type="text"] {
        border: none;
        padding-left: pxToRem(14);
        &:focus {
            outline: none;
        }
    }
    input[type="color"] {
        width: $color-height;
        height: $color-height;
        flex-shrink: 0;
        padding: 1px;
        border: none;
        margin: 0;
        background: transparent;
        cursor: pointer;

        &::-webkit-color-swatch {
            border: 1px solid map-get($grey, 5);
            border-radius:  0 pxToRem(4) pxToRem(4) 0;
        }
        &::-moz-color-swatch {
            border: 1px solid map-get($grey, 5);
            border-radius: pxToRem(4);
        }

        &::-webkit-color-swatch-wrapper {
            padding: 0;
            margin: 0;
        }
        &::-moz-color-swatch-wrapper {
            padding: 0;
            margin: 0;
        }
    }
}
