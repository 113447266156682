.progress_container,
.progress {
  background-color: #edece9;
  position: relative;
  height: 7px;
  width: 100%;
  overflow: hidden;
}
.progress_container {
  border-radius: 5px;
}
.progress {
  width: 10%;
  transition: width 0.4s linear;
}
