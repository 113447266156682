@import "/src/styles/functions";

.cs_center {
  display: flex;
  justify-items: center;
  align-items: center;
}

.cs_mr_8 {
  margin-right: pxToRem(8);
}

.cs_dis_flex {
  display: flex;
}

.cs_pointer {
  cursor: pointer;
}

.cs_btn_disable {
  opacity: 0.5;
}

.cs_pointer_disable {
  cursor: not-allowed;
}

.cs_button {
  background: none;
  border: none;
}

.favourite {
  background-color: red;
}
