@import "/src/styles/functions";
@import "/src/styles/colors";

.widget_heading {
  margin-bottom: pxToRem(24);
}

.widget_cards {
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: pxToRem(20);
  align-items: stretch;
}
