@import "/src/styles/functions";

.main_wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  padding: pxToRem(7);
  border: 1px solid #e0e0e0;
  border-radius: pxToRem(4);
  color: black;
}

.main_wrapper input {
  max-width: 100%;
  min-width: 10%;
  border: none;
  outline: none;
  padding: pxToRem(5);
}

.tag_wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: pxToRem(4);
}

.tag {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  color: #41434c;
  padding: pxToRem(4) pxToRem(10);
  background: #edece9;
  border-radius: 4px;
}

.tag_cross_button {
  display: flex;
  border: none;
  outline: none;
  background-color: unset;
  cursor: pointer;
}
